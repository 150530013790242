body {
  font-size: 14px;
}

.editor-edit{
  display: flex;
  flex-direction: column;
}

.editor-edit .server-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.editor-edit .server-button button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0;
  border-style: none;
  margin-left: 10px;
  margin-right: 45px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}

/* 마지막 버튼 스타일 */
.editor-edit .server-button button:last-child {
  margin-right: 0;
}

.editor-edit .server-button button:hover{
  background-color:#D9F1F1;
}

/* .editor-edit {
 background-color: #F9F9FA;
} */

.editor-edit .editorMode{
  background-color: #F9F9FA;
  margin-bottom: 7px;
  padding: 10px;
} 
.editor{
  margin-bottom: 15px;
}


#tags-container_in{
  display: flex;
  flex-direction: row;
  width: 0;
  margin-top: 1.5rem;
  /* margin-bottom: 0.6rem; */
  background-color: aqua;
}

#tags-container_in #key-container {
  display: flex;
  flex-direction: column;
  width : 4vw;
}

#key-container>p {
  margin: 0;
}

#value-container >button {
  border: none;
  background-color: #FFFFFF;
  color: #9A9DA0;
  margin-left: 1rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}
#value-container >button:hover{
  background-color: #D9F1F1;
  color : #24ABA8
}