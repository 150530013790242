#side-bar {
  position: fixed;
  top: 230px;
  right: 2rem;
  max-height: calc(1vh + 600px);

  width: 78px;
  height: 150px;
  background-color: #EDFAFA;
  border-radius: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-rigt: 30%;
}

#side-bar>button {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  align-items: center;
  justify-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

#side-bar>button:hover{
  background-color: #BADEDE;
  cursor: pointer;
}

.examlab-image-right {
  /* width: 30%; */
}
.editor-edit>div>hr {
  margin-bottom: 30px;
}
.edit-exam {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  /* border: 1px solid #D6D6D6; */
  padding: 0;
  align-items: center;
  width: 1147px;
}
.edit-exam .editor-edit{
  width: 100%;
}
/* .editor-exam {
  padding: 20px;
  background-color: #F9F9FA;
} */

.editor-exam .editor-Tool button {
  background-color: transparent;
  border-style: none;
  margin-right: 10px;
}

.editor-exam .server-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.editor-exam .server-button button {
  background-color: transparent;
  border-style: none;
  margin-right: 45px;
}

/* 마지막 버튼 스타일 */
.editor-exam .server-button button:last-child {
  margin-right: 0;
}

.editor-exam .editor {
  /* font-size: 10px; */
  padding: 20px;
  border: 1px solid #F9F9FA;
  background-color: #FFFFFF;
  margin-bottom: 10px;
}

.editor-exam .editor-Tool {
  padding: 20px;
  border: 1px solid #F9F9FA;
  background-color: #FFFFFF;
}

.editor-exam .editor-Tool {
  display: flex;
}

.editor-exam .editor-Tool>p {
  display: flex;
  color:#24ABA8;
  font-weight: bold;
  margin-right: 15px;
}

.editor-exam .editor-exam-out-line {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #F9F9FA;
}

#tags-container{
  display: flex;
  margin-top: 1.5rem;
  /* margin-bottom: 0.6rem; */
}


#key-container {
  width : 4vw;
}

#key-container>p {
  margin: 0;
}

#value-container >button {
  border: none;
  background-color: #FFFFFF;
  color: #9A9DA0;
  margin-left: 1rem;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}
#value-container >button:hover{
  background-color: #D9F1F1;
  color : #24ABA8
}
.editor-edit .editor-out-line {
  padding: 15px;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  width: 1098px;
  margin-bottom: 20px;
  /* height: 280px; */
  /* align-items: center; */
}

.editor-edit .title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* .editor {
  padding: 16px 24px;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  width: 600px;
} */

/* 
.editor {
  display: flex;
  align-items: center;
} */

/* button {
  align-self: flex-start;
}
*/

/* .editor { */
/* align-items: center; */
/* display: flex;
  flex-direction: column;
  justify-content: center; */
/* } */